import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import { Sortable } from 'sortablejs'

export default class extends CheckboxSelectAll {
  static targets = [
    'optionTemplate',
    'optionValueTemplate',
    'optionsContainer',
    'optionFormTemplate',
    'newOptionForm',
    'newOptionValuesInput',
    'newOptionNameInput',
    'newOptionButton',
    'newOptionButtonLabel',
    'option',
    'variantsContainer',
    'variantTemplate',
    'variantsTable',
    'deleteButton',
    'checkboxAll',
    'checkbox',
    'stockItemsCount'
  ]

  static values = {
    productId: String,
    options: Object,
    variants: Array,
    stock: Object,
    prices: Object,
    currentCurrency: String,
    currencies: Array,
    variantIds: Object,
    defaultStockLocationId: String
  }

  connect() {
    super.connect()
    this.optionNameOptions = this.newOptionNameInputTarget.options
    this.sortable = new Sortable(this.optionsContainerTarget, {
      group: 'options',
      animation: 150,
      onEnd: this.reorderOptions.bind(this),
      handle: '.draggable',
      filter: '.color-option',
      draggable: '.options-creator__option'
    })
    this.ignoredVariants = new Set()
    this.productFormController = this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller*="product-form"]'),
      'product-form'
    )
  }

  toggleQuantityForm() {
    this.variantTemplateTarget.content
      .querySelectorAll('.column-quantity')
      .forEach((el) => el.classList.toggle('d-none'))
  }

  toggle(e) {
    super.toggle(e)
    this.toggleDeleteButton()
  }

  refresh() {
    super.refresh()
    this.toggleDeleteButton()
  }

  toggleDeleteButton() {
    if (this.checked.length > 0) {
      this.deleteButtonTarget.classList.remove('d-none')
    } else {
      this.deleteButtonTarget.classList.add('d-none')
    }
  }

  deleteSelected() {
    const newStockValue = this.stockValue
    const newPricesValue = this.pricesValue
    this.checked.forEach((checkbox) => {
      const internalName = checkbox.value

      this.ignoredVariants.add(internalName)
      const variant = this.variantsContainerTarget.querySelector(`[data-variant-name="${internalName}"]`)

      const nestingLevel = internalName.split('/').length
      if (nestingLevel === 1) {
        const firstOptionKey = Object.keys(this.optionsValue)[0]
        const newOptionValues = this.optionsValue[firstOptionKey].values.filter((value) => value !== internalName)
        if (newOptionValues.length === 0) {
          const newOptionsValue = this.optionsValue
          delete newOptionsValue[firstOptionKey]
          this.optionsValue = newOptionsValue
          this.optionsContainerTarget.querySelector(`#option-${firstOptionKey}`).remove()
        } else {
          this.optionsValue = {
            ...this.optionsValue,
            [firstOptionKey]: {
              ...this.optionsValue[firstOptionKey],
              values: newOptionValues
            }
          }

          this.optionsContainerTarget.querySelector(`#option-${firstOptionKey} [data-name="${internalName}"]`).remove()
        }
        checkbox.checked = false
      }

      delete newStockValue[internalName]
      delete newPricesValue[internalName]
      variant.remove()
    })

    this.stockValue = newStockValue
    this.pricesValue = newPricesValue

    this.checkboxAllTarget.checked = false
    this.refresh()
    this.refreshParentInputs()
  }

  reorderOptions(event) {
    const optionId = event.item.id.replace('option-', '')
    const newPosition = event.newDraggableIndex + 1
    const oldPosition = event.oldDraggableIndex + 1
    const options = Object.keys(this.optionsValue).reduce((acc, key) => {
      if (key === optionId) {
        acc[key] = { ...this.optionsValue[key], position: newPosition }
      } else if (newPosition < oldPosition) {
        if (this.optionsValue[key].position >= newPosition && this.optionsValue[key].position < oldPosition) {
          acc[key] = { ...this.optionsValue[key], position: this.optionsValue[key].position + 1 }
        } else {
          acc[key] = this.optionsValue[key]
        }
      } else {
        if (this.optionsValue[key].position > oldPosition && this.optionsValue[key].position <= newPosition) {
          acc[key] = { ...this.optionsValue[key], position: this.optionsValue[key].position - 1 }
        } else {
          acc[key] = this.optionsValue[key]
        }
      }
      return acc
    }, {})
    this.optionsValue = options
  }

  updateShopLocationCountOnHand(_event) {
    const inputs = this.variantsContainerTarget.querySelectorAll(
      "input[data-slot='[stock_items_attributes][0][count_on_hand]_input'][name$='[count_on_hand]']"
    )
    const sum = Array.from(inputs).reduce((acc, input) => {
      if (input.value === '') return acc
      return acc + parseInt(input.value)
    }, 0)
    this.stockItemsCountTarget.textContent = sum
  }

  updateCurrency({ target: { value: newCurrency } }) {
    this.currentCurrencyValue = newCurrency
    this.currenciesValue.forEach((currency) => {
      this.variantsContainerTarget
        .querySelectorAll(`.price-input-container:has(input[data-slot="[price][${currency}]_input"])`)
        .forEach((el) => {
          if (currency === newCurrency) {
            el.classList.remove('d-none')
            el.classList.add('d-flex')
          } else {
            el.classList.remove('d-flex')
            el.classList.add('d-none')
          }
        })
    })
  }

  optionsValueChanged() {
    if (this.hasNewOptionButtonTarget) {
      const label = this.newOptionButtonLabelTarget

      if (Object.values(this.optionsValue).filter(Boolean).length) {
        label.textContent = label.dataset.hasOptionsText
      } else {
        label.textContent = label.dataset.noOptionsText
      }
    }
    this.refreshTomSelect()
    this.variantsValue = this.generateVariants()
  }

  calculateVariantName(variant, keys, i) {
    let name = ''
    let internalName = name
    if (i === 0) {
      name = variant[keys[i]]
      internalName = name
    } else {
      const namesPath = keys.slice(1, keys.length).map((key) => variant[key])
      name = namesPath.join(' / ')
      internalName = `${variant[keys[0]]}/${namesPath.join('/')}`
    }

    return { name, internalName }
  }

  updateParentCountOnHand(event) {
    const { variantName } = event.target.closest('[data-variants-form-target="variant"]').dataset
    const childrenCountOnHand = this.variantsContainerTarget.querySelectorAll(
      `[data-variant-name^="${variantName}/"] input[data-slot="[stock_items_attributes][0][count_on_hand]_input"]`
    )
    const parentCountOnHand = event.target.value

    childrenCountOnHand.forEach((countOnHandInput) => {
      countOnHandInput.value = parentCountOnHand
    })

    const childrenCountOnHandKeys = this.variantsValue
      .filter((variant) => variant.internalName.startsWith(variantName))
      .map((variant) => variant.internalName)

    this.stockValue = {
      ...this.stockValue,
      ...childrenCountOnHandKeys.reduce((acc, key) => {
        acc[key] = { ...this.stockValue[key], count_on_hand: parentCountOnHand }
        return acc
      }, {})
    }

    this.updateShopLocationCountOnHand(event)
  }

  updateParentPrice(event) {
    const { variantName } = event.target.closest('[data-variants-form-target="variant"]').dataset
    const currency = event.target.dataset.currency
    const childrenPrices = this.variantsContainerTarget.querySelectorAll(
      `[data-variant-name^="${variantName}/"] input[data-slot="[price][${currency}]_input"]`
    )
    const parentPrice = event.target.value

    childrenPrices.forEach((priceInput) => {
      priceInput.value = parentPrice
    })
    const childrenPricesKeys = this.variantsValue
      .filter((variant) => variant.internalName.startsWith(variantName))
      .map((variant) => variant.internalName)

    childrenPricesKeys.forEach((key) => {
      this.updatePriceForVariant(key, parentPrice, currency)
    })
  }

  selectChildVariants(event) {
    const { variantName } = event.target.closest('[data-variants-form-target="variant"]').dataset

    const children = this.variantsContainerTarget.querySelectorAll(
      `[data-variant-name^="${variantName}/"] input[type="checkbox"]`
    )

    children.forEach((child) => {
      child.checked = event.target.checked
    })

    this.refresh()
  }

  prepareParentVariant(existingVariant, internalName) {
    this.currenciesValue.forEach((currency) => {
      const parentPriceInput = existingVariant.querySelector(`input[data-slot="[price][${currency}]_input"]`)
      parentPriceInput.name = ''
      parentPriceInput.dataset.action = `input->variants-form#updateParentPrice`
    })

    const parentCountOnHandInput = existingVariant.querySelector(
      'input[data-slot="[stock_items_attributes][0][count_on_hand]_input"]'
    )
    parentCountOnHandInput.name = ''
    parentCountOnHandInput.dataset.action = 'input->variants-form#updateParentCountOnHand'

    const checkbox = existingVariant.querySelector('input[type="checkbox"][id^="checkbox_"]')
    if (!checkbox) return
    const checkboxLabel = existingVariant.querySelector('label[for^="checkbox_"]')
    checkbox.id = `parent_checkbox_${internalName}`
    checkboxLabel.htmlFor = `parent_checkbox_${internalName}`
    checkbox.dataset.action = 'click->variants-form#selectChildVariants'
    checkbox.value = internalName
  }

  updatePrice(event) {
    const { variantName } = event.target.closest('[data-variants-form-target="variant"]').dataset
    const currency = event.target.dataset.currency
    const nestingLevel = variantName.split('/').length
    this.updatePriceForVariant(variantName, event.target.value, currency)
    if (nestingLevel > 1) {
      const parentName = variantName.split('/')[0]
      this.updateParentPriceRange(parentName, currency)
    }
  }

  updateCountOnHand(event) {
    const variantEl = event.target.closest('[data-variants-form-target="variant"]')
    const variantName = variantEl.dataset.variantName
    const stockLocationIdInput = variantEl.querySelector(
      '[data-slot="[stock_items_attributes][0][stock_location_id]_input"]'
    )

    const nestingLevel = variantName.split('/').length

    const stockLocationId = stockLocationIdInput.value
    const countOnHand = event.target.value

    this.stockValue = {
      ...this.stockValue,
      [variantName]: { stock_location_id: stockLocationId, count_on_hand: countOnHand }
    }

    if (nestingLevel > 1) {
      const parentName = variantName.split('/')[0]
      this.updateParentStockSum(parentName)
    }

    this.updateShopLocationCountOnHand(event)
  }

  updateParentPriceRange(variantName, currency) {
    const parentPriceEl = this.variantsContainerTarget.querySelector(
      `div:not(.nested)[data-variant-name="${variantName}"] [data-slot="[price][${currency}]_input"]`
    )
    if (!parentPriceEl) return

    const currentVariantKeys = Array.from(
      this.variantsContainerTarget.querySelectorAll(`[data-variant-name^="${variantName}/"]`)
    ).map((el) => el.dataset.variantName)

    const pricesVariation = new Set(
      Object.keys(this.pricesValue)
        .filter((key) => currentVariantKeys.includes(key))
        .map((key) => this.priceForVariant(key, currency))
    )

    if (pricesVariation.size === 0) {
      parentPriceEl.value = this.priceForVariant(variantName, currency)
      parentPriceEl.placeholder = ''
      return
    }
    const minPrice = Math.min(...pricesVariation)
    const maxPrice = Math.max(...pricesVariation)

    if (minPrice !== maxPrice) {
      parentPriceEl.value = null
      parentPriceEl.placeholder = `${minPrice} - ${maxPrice}`
    } else {
      parentPriceEl.value = minPrice
    }
  }

  updateParentStockSum(variantName) {
    const parentStockEl = this.variantsContainerTarget.querySelector(
      `div:not(.nested)[data-variant-name="${variantName}"] [data-slot="[stock_items_attributes][0][count_on_hand]_input"]`
    )
    if (!parentStockEl) return

    const currentVariantKeys = Array.from(
      this.variantsContainerTarget.querySelectorAll(`[data-variant-name^="${variantName}/"]`)
    ).map((el) => el.dataset.variantName)

    if (currentVariantKeys.length === 0) {
      parentStockEl.value = this.stockValue[variantName].count_on_hand
      parentStockEl.placeholder = ''
      this.updateShopLocationCountOnHand(null)
      return
    }

    const countsOnHand = Object.keys(this.stockValue)
      .filter((key) => currentVariantKeys.includes(key))
      .map((key) => this.stockValue[key].count_on_hand)

    const sum = countsOnHand.reduce((acc, value) => acc + parseInt(value) || 0, 0)

    parentStockEl.placeholder = String(sum)
    parentStockEl.value = null
  }

  variantsValueChanged() {
    let keys = Object.keys(this.variantsValue[0] || {}).filter((key) => key !== 'internalName')

    const currentVariants = new Set()

    if (keys.length) {
      this.variantsTableTarget.classList.remove('d-none')

      const nestingLevel = Math.min(keys.length, 2)
      let idx = 0

      for (let i = 0; i < nestingLevel; i++) {
        this.variantsValue.forEach((variant) => {
          const { name, internalName } = this.calculateVariantName(variant, keys, i)
          if (currentVariants.has(internalName)) {
            idx++
            return
          }
          currentVariants.add(internalName)

          const existingVariant = this.variantsContainerTarget.querySelector(`[data-variant-name="${internalName}"]`)
          if (existingVariant) {
            if (i === 0 && nestingLevel > 1) {
              existingVariant.querySelectorAll("input[type='hidden']").forEach((input) => input.remove())

              this.prepareParentVariant(existingVariant, internalName)
            }
            idx++
            return
          }

          const template = this.variantTemplateTarget.content.cloneNode(true)
          const variantNameContainer = template.querySelector('[data-slot="variantName"]')
          const variantTarget = template.querySelector('[data-variants-form-target="variant"]')
          variantTarget.dataset.variantName = internalName

          const variantId = this.variantIdsValue[internalName]
          if (variantId) {
            variantTarget.querySelector('[data-slot="variantEditButton"]').href = `/admin/products/${this.productIdValue}/variants/${variantId}/edit`
            variantTarget.querySelector('[data-slot="variantEditButton"]').classList.remove('invisible')
          }

          let previousVariant = null

          if (i > 0) {
            const { internalName: parentInternalName } = this.calculateVariantName(variant, keys, 0)
            const variantsInThisGroup = this.variantsContainerTarget.querySelectorAll(
              `[data-variant-name^="${parentInternalName}/"]`
            )
            if (variantsInThisGroup.length > 0) {
              // If there are already variants in this option type then we want to render this variant after the last variant in the group
              previousVariant = variantsInThisGroup[variantsInThisGroup.length - 1]
            } else {
              // Otherwise we want to render this variant after the parent variant
              previousVariant = this.variantsContainerTarget.querySelector(
                `[data-variant-name="${parentInternalName}"]`
              )
            }
            variantTarget.classList.add('nested')
          } else if (nestingLevel > 1) {
            template.querySelectorAll("input[type='hidden']").forEach((input) => input.remove())
            this.prepareParentVariant(template, internalName)
          }

          if (i === nestingLevel - 1) {
            const inputs = this.createInputsForVariant(keys, variant, idx)
            inputs.forEach((input) => {
              variantTarget.appendChild(input)
            })

            const checkbox = template.querySelector('input[type="checkbox"]#checkbox_')
            if (checkbox) {
              const checkboxLabel = template.querySelector('label[for="checkbox_"]')
              checkbox.id = `checkbox_${internalName}`
              checkboxLabel.htmlFor = `checkbox_${internalName}`
              checkbox.value = internalName
            }

            this.currenciesValue.forEach((currency) => {
              const priceInput = variantTarget.querySelector(`input[data-slot="[price][${currency}]_input"]`)
              priceInput.name = `product[variants_attributes][${idx}][price][${currency}]`
              if (currency === this.currentCurrencyValue) {
                priceInput.parentElement.classList.remove('d-none')
                priceInput.parentElement.classList.add('d-flex')
              } else {
                priceInput.parentElement.classList.remove('d-flex')
                priceInput.parentElement.classList.add('d-none')
              }

              const existingPrice = this.priceForVariant(internalName, currency)
              priceInput.value = existingPrice
            })

            this.prepareStockInputs(variantTarget, internalName, idx)
          }
          idx++

          variantNameContainer.textContent = name
          if (previousVariant) {
            previousVariant.after(template)
          } else {
            this.variantsContainerTarget.appendChild(template)
          }
        })
      }
    } else {
      this.variantsTableTarget.classList.add('d-none')
    }

    this.variantsContainerTarget.querySelectorAll('[data-variants-form-target="variant"]').forEach((variant) => {
      const variantName = variant.dataset.variantName
      if (!currentVariants.has(variantName)) {
        variant.remove()
      }
    })

    // When going back from variant edit page the `variantsValueChanged` method is called before the `connect` method, so `this.productFormController` is not set yet

    if (this.productFormController?.hasQuantityFormTarget) {
      if (this.variantsValue.length) {
        this.productFormController.quantityFormTarget.classList.add('d-none')
        this.productFormController.hasVariantsValue = true
      } else {
        if (this.productFormController.trackInventoryCheckboxTarget.checked) {
          this.productFormController.quantityFormTarget.classList.remove('d-none')
        }

        this.productFormController.hasVariantsValue = false
      }
    }

    this.refreshParentInputs()
  }

  refreshParentInputs() {
    const firstOption = Object.values(this.optionsValue)[0]
    if (firstOption) {
      firstOption.values.forEach((name) => {
        this.currenciesValue.forEach((currency) => {
          this.updateParentPriceRange(name, currency)
        })
        this.updateParentStockSum(name)
        this.updateShopLocationCountOnHand(null)
      })
    }
  }

  createInputsForVariant(keys, variant, i) {
    const inputs = []
    if (this.variantIdsValue[variant.internalName]) {
      const idInput = document.createElement('input')
      idInput.type = 'hidden'
      idInput.name = `product[variants_attributes][${i}][id]`
      idInput.value = this.variantIdsValue[variant.internalName]
      inputs.push(idInput)
    }

    keys.forEach((key) => {
      const nameInput = document.createElement('input')
      nameInput.type = 'hidden'
      nameInput.name = `product[variants_attributes][${i}][options][][name]`
      nameInput.value = key
      inputs.push(nameInput)

      const positionInput = document.createElement('input')
      positionInput.type = 'hidden'
      positionInput.name = `product[variants_attributes][${i}][options][][position]`
      positionInput.value = Object.values(this.optionsValue)
        .filter(Boolean)
        .find((option) => option.name === key).position
      inputs.push(positionInput)

      const valueInput = document.createElement('input')
      valueInput.type = 'hidden'
      valueInput.name = `product[variants_attributes][${i}][options][][value]`
      valueInput.value = variant[key]
      inputs.push(valueInput)
    })

    return inputs
  }

  prepareStockInputs(variantTarget, internalName, idx) {
    const stockInput = variantTarget.querySelector(
      'input[data-slot="[stock_items_attributes][0][count_on_hand]_input"]'
    )
    const stockLocationIdInput = variantTarget.querySelector(
      'input[data-slot="[stock_items_attributes][0][stock_location_id]_input"]'
    )
    const stockIdInput = variantTarget.querySelector('input[data-slot="[stock_items_attributes][0][id]_input"]')
    let existingStock = this.stockValue[internalName]
    let oldStock = null
    if (!existingStock) {
      const oldInternalName = internalName.split('/').slice(0, -1).join('/')
      oldStock = this.stockValue[oldInternalName]?.count_on_hand
    }
    if (!existingStock) {
      const stock = oldStock ?? 0
      stockInput.value = stock
      existingStock = {
        count_on_hand: stock,
        stock_location_id: this.defaultStockLocationIdValue
      }
      this.stockValue = { ...this.stockValue, [internalName]: existingStock }
    }

    stockInput.name = `product[variants_attributes][${idx}][stock_items_attributes][0][count_on_hand]`
    stockLocationIdInput.name = `product[variants_attributes][${idx}][stock_items_attributes][0][stock_location_id]`
    stockIdInput.name = `product[variants_attributes][${idx}][stock_items_attributes][0][id]`

    stockInput.value = existingStock.count_on_hand
    stockLocationIdInput.value = existingStock.stock_location_id
    if (existingStock.id) {
      stockIdInput.value = existingStock.id
    }
  }

  addOption(name, option_values = [], id) {
    let color = false
    let position = Object.keys(this.optionsValue).length + 1
    let newOptionsPositions = {}

    const template = this.optionTemplate(name, option_values, id, color)

    this.optionsValue = {
      ...this.optionsValue,
      ...newOptionsPositions,
      [id.toLowerCase().replaceAll(' ', '-')]: {
        name,
        values: option_values,
        position: position
      }
    }

    if (position === 1) {
      this.optionsContainerTarget.prepend(document.importNode(template, true))
    } else {
      this.optionsContainerTarget.appendChild(document.importNode(template, true))
    }
  }

  handleNewOption(event) {
    const newOptionName = this.newOptionNameInputTarget.options[this.newOptionNameInputTarget.selectedIndex].text
    const newOptionId = this.newOptionNameInputTarget.value
    const newOptionValues = this.newOptionValuesInputTarget.values()
    if (
      !newOptionName.length ||
      this.optionsValue[newOptionId.toLowerCase().replaceAll(' ', '-')] ||
      newOptionValues.length === 0 ||
      Object.values(this.optionsValue)
        .filter(Boolean)
        .map((v) => v.name)
        .includes(newOptionName)
    ) {
      return
    }

    this.addOption(newOptionName, newOptionValues, newOptionId)

    this.hideNewOptionForm()
  }

  hideNewOptionForm() {
    this.newOptionNameInputTarget.tomselect.clear()
    this.newOptionValuesInputTarget.reset()

    this.newOptionFormTarget.classList.add('d-none')

    this.newOptionButtonTarget.classList.remove('d-none')
  }

  editOption(event) {
    const { optionId } = event.params
    const option = this.optionsContainerTarget.querySelector(`#option-${optionId}`)

    const { name, values } = this.optionsValue[optionId]

    const form = this.optionFormTemplate(name, values, optionId)

    option.replaceWith(form)
  }

  saveOption(event) {
    const { optionId } = event.params
    const option = this.optionsContainerTarget.querySelector(`#option-${optionId.toLowerCase().replaceAll(' ', '-')}`)
    const optionForm = option.closest('[data-slot="optionForm"]')
    const optionNameSelect = option.querySelector('select[name="option_name"]')
    const optionName = optionNameSelect.options[optionNameSelect.selectedIndex].text
    const newId = optionNameSelect.value

    if ((newId != optionId && this.optionsValue[newId.toLowerCase()]) || !optionName.length) {
      return
    }

    let color = false
    let position = this.optionsValue[optionId.toLowerCase()].position
    let newOptionsPositions = {}

    const optionValues = option.querySelector('[data-slot="optionValuesInput"]').values()

    if (optionValues.length === 0) {
      return
    }

    const template = this.optionTemplate(optionName, optionValues, newId, color)

    if (color) {
      this.optionsContainerTarget.prepend(template)
      optionForm.remove()
    } else {
      optionForm.replaceWith(template)
    }

    const newOptions = {
      ...this.optionsValue,
      ...newOptionsPositions,
      [newId.toLowerCase()]: {
        ...this.optionsValue[optionId.toLowerCase()],
        name: optionName,
        values: optionValues,
        position: position
      }
    }
    if (optionId != newId) {
      delete newOptions[optionId.toLowerCase()]
    }

    this.optionsValue = newOptions
  }

  showNewOptionForm(event) {
    event.preventDefault()
    this.newOptionFormTarget.classList.remove('d-none')
    this.newOptionButtonTarget.classList.add('d-none')
  }

  optionTemplate(name, values, id, color = false) {
    const template = this.optionTemplateTarget.content.cloneNode(true)
    template.querySelectorAll('[data-variants-form-option-id-param]').forEach((el) => {
      el.dataset.variantsFormOptionIdParam = id.toLowerCase().replaceAll(' ', '-')
    })
    const optionName = template.querySelector('[data-slot="optionName"]')
    const optionValuesTemplates = this.optionValueTemplate(values)
    const optionValuesContainer = template.querySelector('[data-slot="optionValuesContainer"]')
    optionValuesTemplates.forEach((optionValueTemplate) => {
      optionValuesContainer.appendChild(optionValueTemplate)
    })
    const mainContainer = template.querySelector('[data-variants-form-target="option"]')
    mainContainer.id = 'option-' + id.toLowerCase().replaceAll(' ', '-')

    optionName.textContent = name
    if (color) {
      mainContainer.classList.add('color-option')
      mainContainer.querySelector('.draggable').disabled = true
    }
    return template
  }

  discardOption(event) {
    const { optionId } = event.params
    const option = this.optionsContainerTarget.querySelector(`#option-${optionId}`)
    const optionName = this.optionsValue[optionId.toLowerCase()].name
    option.remove()
    this.optionsValue = { ...this.optionsValue, [optionId.toLowerCase()]: null }
    this.refreshParentInputs()
  }

  optionFormTemplate(optionName, optionValues, id) {
    const template = this.optionFormTemplateTarget.content.cloneNode(true)

    const optionNameSelect = template.querySelector('select[name="option_name"]')

    let optionExists = false
    // If options includes the optionName, set it as selected
    optionNameSelect.querySelectorAll('option').forEach((option) => {
      if (option.value === id) {
        option.selected = true
        optionExists = true
      }
    })
    // Otherwise, create a new option, and select it
    if (!optionExists) {
      const newOption = document.createElement('option')
      newOption.text = optionName
      newOption.value = id
      newOption.selected = true
      optionNameSelect.appendChild(newOption)
    }

    const optionValuesInput = template.querySelector('[data-slot="optionValuesInput"]')
    optionValuesInput.dataset.multiInputPreloadedValuesValue = JSON.stringify(optionValues)

    template.querySelectorAll('[data-variants-form-option-id-param]').forEach((el) => {
      el.dataset.variantsFormOptionIdParam = id
    })

    const mainContainer = template.querySelector('[data-slot="optionForm"]')
    mainContainer.id = 'option-' + id.toLowerCase().replaceAll(' ', '-')

    return template
  }

  optionValueTemplate(values) {
    const templates = []

    values.forEach((value) => {
      const template = this.optionValueTemplateTarget.content.cloneNode(true)
      const optionValueNameEl = template.querySelector('[data-slot="optionValueName"]')
      optionValueNameEl.textContent = value
      optionValueNameEl.dataset.name = value

      templates.push(template)
    })

    return templates
  }

  refreshTomSelect() {
    const alreadySelectedOptions = Object.keys(this.optionsValue).filter((k) => this.optionsValue[k] !== null)

    Array.from(this.newOptionNameInputTarget.options).forEach((option) => {
      const tomselectOption = this.newOptionNameInputTarget.tomselect?.getOption(option.value)
      if (!tomselectOption) return
      const alreadySelected = alreadySelectedOptions.includes(option.value)
      tomselectOption.ariaDisabled = alreadySelected
      if (alreadySelected) {
        tomselectOption.removeAttribute('data-selectable')
      } else {
        tomselectOption.setAttribute('data-selectable', '')
      }
      tomselectOption.disabled = alreadySelected
    })

    this.newOptionNameInputTarget.tomselect?.sync()
    this.newOptionNameInputTarget.tomselect?.refreshOptions()
  }

  generateVariants() {
    const options = Object.values(this.optionsValue)
      .filter(Boolean)
      .sort((a, b) => a.position - b.position)

    if (options.length === 0) {
      return []
    }

    const optionValues = options.map((option) => option.values)
    const optionNames = options.map((option) => option.name)

    const cartesianProduct = this.cartesianProduct(optionValues)

    return cartesianProduct.map((variant) => {
      variant = Array.isArray(variant) ? variant : [variant]
      const variantObj = variant.reduce((acc, value, index) => {
        acc[options[index].name] = value
        return acc
      }, {})
      variantObj.internalName = this.calculateVariantName(variantObj, optionNames, optionNames.length - 1).internalName
      return variantObj
    })
  }

  cartesianProduct(arr) {
    return arr.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())))
  }

  priceForVariant(variantName, currency) {
    const existingPrice = this.pricesValue[variantName]?.[currency.toLowerCase()]
    if (existingPrice) return parseFloat(existingPrice)

    return null
  }

  updatePriceForVariant(variantName, price, currency) {
    this.pricesValue = {
      ...this.pricesValue,
      [variantName]: {
        ...this.pricesValue[variantName],
        [currency.toLowerCase()]: parseFloat(price)
      }
    }
  }
}
