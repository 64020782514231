import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'trackInventoryCheckbox',
    'quantityForm',
    'titleInput',
    'descriptionInput',
    'availableOn',
    'makeActiveAt',
    'discontinueOn',
    'status'
  ]

  static values = {
    hasVariants: Boolean
  }

  toggleQuantityForm() {
    if (this.hasQuantityFormTarget && !this.hasVariantsValue) {
      if (this.trackInventoryCheckboxTarget.checked) {
        this.quantityFormTarget.classList.remove('d-none')
      } else {
        this.quantityFormTarget.classList.add('d-none')
      }
    }

    this.element.querySelectorAll('.column-quantity').forEach((el) => el.classList.toggle('d-none'))

    this.dispatch('toggle-quantity-form')
  }

  switchAvailabilityDatesFields(event) {
    let status = event.target.value
    if (status === "draft" || status === "ready_for_review") {
      this.show(this.availableOnTarget)
      this.show(this.makeActiveAtTarget)
    } else if (status === "active") {
      this.show(this.availableOnTarget)
      this.hide(this.makeActiveAtTarget)
    } else {
      this.hide(this.availableOnTarget)
      this.hide(this.makeActiveAtTarget)
    }
  }

  show(element) {
    element.classList.remove('d-none')
  }

  hide(element) {
    element.classList.add('d-none')
  }
}
