import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'template',
    'carrier',
    'credentials',

    'credentialsAccountNumber',
    'credentialsUsername',
    'credentialsPassword',
    'credentialsSiteId',
    'credentialsClientId',
    'credentialsClientName'
  ]

  static values = {
    credentials: Object
  }

  connect() {
    super.connect()
    this.changeCredentials()
  }


  get carrier() {
    return this.carrierTarget.value
  }

  get credentials() {
    return this.credentialsValue
  }

  get carrierTemplate() {
    return this.templateTargets.find(template => template.dataset.slug == this.carrierTarget.value)
  }

  changeCredentials() {
    const carrierInputs = this.carrierTemplate.content.cloneNode(true)
    this.credentialsTarget.replaceChildren(carrierInputs)

    const slug = this.carrier

    switch(slug) {
      case 'dhl':
        this.credentialsAccountNumberTarget.value = this.credentials['account_number'] || ''
        this.credentialsPasswordTarget.value = this.credentials['password'] || ''
        this.credentialsSiteIdTarget.value = this.credentials['site_id'] || ''
        break
      case 'dpd-uk':
        this.credentialsUsernameTarget.value = this.credentials['username'] || ''
        this.credentialsPasswordTarget.value = this.credentials['password'] || ''
        break
      case 'evri':
        this.credentialsClientIdTarget.value = this.credentials['client_id'] || ''
        this.credentialsClientNameTarget.value = this.credentials['client_name'] || ''
        this.credentialsUsernameTarget.value = this.credentials['username'] || ''
        this.credentialsPasswordTarget.value = this.credentials['password'] || ''
        break
      case 'fedex':
        this.credentialsAccountNumberTarget.value = this.credentials['account_number'] || ''
        break
    }
  }
}
