import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select/dist/esm/tom-select.complete.js';
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String, // remote URL to fetch options
    options: Array, // JSON array of options
    activeOption: String, // array or single value of selected options,
    emptyOption: { type: String, default: null }, // empty option put at the top of the list
    multiple: { type: Boolean, default: false },
    valueField: { type: String, default: 'id' },
    searchField: { type: String, default: 'name' },
    labelField: { type: String, default: 'name' },
    sortField: { type: String, default: 'name' },
    create: { type: Boolean, default: false }
  }
  static targets = [ "input" ]

  async connect() {
    if (this.urlValue) {
      const response = await get(this.urlValue, { contentType: 'application/json' })
      if (response.ok) {
        const options = await response.json
        this.initTomSelect(options)
      }
    } else {
      this.initTomSelect(this.optionsValue)
    }
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect(options = []) {
    const settings = {
      maxOptions: 1500,
      lockOptgroupOrder: true,
      valueField: this.valueFieldValue,
      searchField: this.searchFieldValue,
      labelField: this.labelFieldValue,
      sortField: this.sortFieldValue,
      create: this.createValue
    }

    if (options.length > 0) {
      if (this.emptyOptionValue) {
        let groupedOptions = options.map(function (option) {
          option.group = 'non-empty'
          return option
        })

        groupedOptions.unshift({ group: 'empty', id: ' ', name: this.emptyOptionValue })

        settings.options = groupedOptions
        settings.optgroupField = 'group'
        settings.optgroups = [
          { value: 'empty', label: '' },
          { value: 'non-empty', label: '' }
        ]
      } else {
        settings.options = options
      }
    }

    if (this.multipleValue) {
      settings.maxItems = null
      settings.plugins = ['remove_button']
      if (this.activeOptionValue) settings.items = JSON.parse(this.activeOptionValue)
    } else {
      if (this.activeOptionValue) settings.items = [this.activeOptionValue]
    }

    this.inputTarget.classList.remove('d-none')
    this.select = new TomSelect(this.inputTarget, settings)
  }
}
