import { Controller } from '@hotwired/stimulus'
import { generateSignature, saveActiveStorageBlob } from '../utilities/cloudinary_uploader'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    assetClass: { type: String, default: 'Spree::Image' },
    viewableId: String,
    viewableType: String,
    multiple: { type: Boolean, default: false },
    type: { type: String, default: 'image' },
  }

  open(event) {
    event.preventDefault()

    this.widget = window.cloudinary.createUploadWidget(
      {
        cloudName: CLOUDINARY_CLOUD_NAME,
        api_key: CLOUDINARY_API_KEY,
        folder: CLOUDINARY_FOLDER,
        type: this.typeValue,
        uploadSignature: generateSignature,
        cropping: false,
        multiple: this.multipleValue,
        uploadPreset: 'ml_default'
      },
      (error, result) => {
        if (!error && result) {
          if (result.event === 'success') {
            this.handleSuccessResult(result)
          }
        }
      }
    )

    this.widget.open()
  }

  async handleSuccessResult(result) {
    // 1 step - save blob to ActiveStorage
    const response = await saveActiveStorageBlob(result)
    
    // 2nd step - save Spree::Image
    if (response.ok) {
      const blobBody = await response.json

      const assetResponse = await post('/admin/assets', { 
        body: JSON.stringify({ 
          asset: {
            type: this.assetClassValue,
            viewable_type: this.viewableTypeValue,
            viewable_id: this.viewableIdValue,
            attachment: blobBody.signed_id
          } 
        }),
        responseKind: 'turbo-stream'
      })

      if (assetResponse.ok) {
        this.dispatch('imageUploaded', { detail: result })
      }
    }
  }
}
