import { Cloudinary } from 'cloudinary-core'

const cloudinary = new Cloudinary

// https://cloudinary.com/blog/how_to_automatically_adapt_website_images_to_retina_and_hidpi_devices
// https://cloudinary.com/documentation/responsive_images#step_1_include_the_cloudinary_javascript_library
// regular turbo visits
document.addEventListener('turbo:load', _event => {
  cloudinary.responsive()
})
// https://turbo.hotwired.dev/reference/events
// turbo frames
document.addEventListener('turbo:frame-load', _event => {
  cloudinary.responsive()
})
// turbo stream, eg. update cart
// we need to use timeout as we can only attach to an event BEFORE it happens
document.addEventListener('turbo:before-stream-render', _event => {
  // cloudinary.responsive()
  setTimeout(() => {
    cloudinary.responsive()
  }, 100)
})

// when changing window size
window.addEventListener('resize', _event => {
  cloudinary.responsive()
})
