import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'titlePreview',
    'slugPreview',
    'descriptionPreview',
    'preview',
    'inputsContainer',
    'titleInput',
    'descriptionInput',
    'slugInput'
  ]

  connect() {
    const productFormController = this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller*="product-form"]'),
      'product-form'
    )
    this.productTitleInputTarget = productFormController.titleInputTarget
    this.productTitleInputTarget.addEventListener('input', this.updatePreviews)

    this.initializeDescriptionInput(productFormController)
  }

  updatePreviews = () => {
    if (this.metaTitle.length === 0 || this.metaDescription.length === 0) {
      this.previewTarget.classList.add('d-none')
      return
    }

    this.previewTarget.classList.remove('d-none')
    this.titlePreviewTarget.textContent = this.metaTitle
    this.slugPreviewTarget.textContent = this.slugInputTarget.value
    this.descriptionPreviewTarget.textContent = this.metaDescription
  }

  toggleInputs() {
    this.inputsContainerTarget.classList.toggle('d-none')
  }

  get metaTitle() {
    return this.titleInputTarget.value.length ? this.titleInputTarget.value : this.productTitleInputTarget.value
  }

  get metaDescription() {
    const metaDescription = this.descriptionInputTarget.value.length
      ? this.descriptionInputTarget.value
      : this.productDescriptionInputTarget.getContent({ format: 'text' })
    if (metaDescription.length > 320) {
      return metaDescription.substring(0, 320) + '...'
    }
    return metaDescription
  }

  initializeDescriptionInput(productFormController) {
    const descriptionEditorName = productFormController.descriptionInputTarget.id

    if (tinymce.editors[descriptionEditorName]) {
      this.productDescriptionInputTarget = tinymce.editors[descriptionEditorName]
      this.productDescriptionInputTarget.on('input', this.updatePreviews)
    } else {
      tinymce.on('AddEditor', (e) => {
        if (e.editor.id !== descriptionEditorName) return
        this.productDescriptionInputTarget = tinymce.get(productFormController.descriptionInputTarget.id)
        this.productDescriptionInputTarget.on('input', this.updatePreviews)
      })
    }
  }
}
