// Entry point for the build script in your package.json
import 'animate.css'

// Rails stuff
import "@rails/actioncable"
import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// 3rd party libraries
import "chartkick/chart.js"
import LocalTime from "local-time"
import "mapkick/bundle"

// Polyfills
import './polyfills/replace_children_polyfill'

// utilities
import './utilities/sentry'
import './utilities/cloudinary'
import './utilities/bootstrap'

// Stimulus controllers
import "./controllers"

LocalTime.start()

document.addEventListener('turbo:before-visit', _event => {
  const content = document.getElementById('content')
  if (content) content.classList.add('blurred')
})

document.addEventListener('turbo:load', _event => {
  const content = document.getElementById('content')
  if (content) content.classList.remove('blurred')
})

document.addEventListener('turbo:submit-start', () => {
  Turbo.navigator.delegate.adapter.progressBar.setValue(0)
  Turbo.navigator.delegate.adapter.progressBar.show()
})
document.addEventListener('turbo:submit-end', () => {
  Turbo.navigator.delegate.adapter.progressBar.setValue(1)
  Turbo.navigator.delegate.adapter.progressBar.hide()
})
