import { Controller } from "@hotwired/stimulus"
import { initializeFlatfile } from "@flatfile/javascript"

import createSubmitDataListener from "./flatfile/create_submit_data_listener"
import { productsWorkbook } from "./flatfile/products_workbook"
import { customersWorkbook } from "./flatfile/customers_workbook"

export default class extends Controller {
  static values = {
    publishableKey: { type: String },
    environmentId: { type: String },
    kind: { type: String, default: 'products' }
  }

  open(event) {
    event.preventDefault()

    const flatfileOptions = {
      name: `Import ${this.kindValue}`,
      publishableKey: this.publishableKeyValue,
      environmentId: this.environmentIdValue,
      workbook: this.workbook,
      listener: createSubmitDataListener(this.kindValue),
      sidebarConfig: {
        showSidebar: false
      }
    }

    initializeFlatfile(flatfileOptions)
  }

  get workbook() {
    switch (this.kindValue) {
      case 'products':
        return productsWorkbook
      case 'customers':
        return customersWorkbook
      default:
        return productsWorkbook
    }
  }
}
