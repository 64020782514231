import { post } from '@rails/request.js'

const generateSignature = async (callback, paramsToSign) => {
  const response = await post('/cloudinary/generate_signature', {
    body: JSON.stringify({
      data: paramsToSign,
    })
  })

  if (response.ok) {
    const body = await response.json
    callback(body.signature)
  } else {
    console.error(response)
  }
}

const prepareBlobPayload = (result) => {
  return JSON.stringify({
    blob: {
      filename: result.info.original_filename,
      content_type: result.info.resource_type + '/' + result.info.format,
      byte_size: result.info.bytes,
      checksum: result.info.etag,
      key: result.info.public_id,
      metadata: result.info
    }
  })
}

const saveActiveStorageBlob = async (result) => {
  const response = await post('/rails/active_storage/cloudinary', { body: prepareBlobPayload(result) })
  return response
}

export { generateSignature, prepareBlobPayload, saveActiveStorageBlob };
