import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  intercept(event) {
    const { response, success } = event.detail.fetchResponse;
    if (success) { return; }

    const { status } = response;
    if (status >= 300 && status < 400) {
      this.handleServerError(response);
    }
    else if (status >= 400 && status < 500){
      this.handleServerError(response);
    }
    else if (status >= 500) {
      this.handleServerError(response);
    }
  }
g
  handleServerError(response) {
    window.location = window.location.href
    alert(`Error has occurred: ${response.statusText}`)
  }
}
