import { Controller } from '@hotwired/stimulus'
import { Cloudinary } from '@cloudinary/url-gen'
import { fill } from '@cloudinary/url-gen/actions/resize'
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity'
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn'
import { generateSignature, saveActiveStorageBlob } from '../utilities/cloudinary_uploader'

export default class extends Controller {
  static targets = ['thumb', 'thumbTemplate', 'toolbar', 'remove', 'placeholder']

  static values = {
    fieldName: String,
    thumbWidth: Number,
    thumbHeight: Number,
    autoSubmit: Boolean,
    multiple: { type: Boolean, default: false },
    crop: { type: Boolean, default: false }
  }

  connect() {
    this.uploader = window.cloudinary.createUploadWidget(
      {
        cloudName: CLOUDINARY_CLOUD_NAME,
        api_key: CLOUDINARY_API_KEY,
        folder: CLOUDINARY_FOLDER,
        type: 'image',
        uploadSignature: generateSignature,
        uploadPreset: 'ml_default',
        multiple: this.multipleValue,
        cropping: this.cropValue,
        croppingShowDimensions: true,
        croppingAspectRatio: this.thumbWidthValue / this.thumbHeightValue,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#2B0AFF',
            tabIcon: '#2B0AFF',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#2B0AFF',
            action: '#2B0AFF',
            inactiveTabIcon: '#495057',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#fff'
          },
          frame: {
            background: '#00000080'
          },
          fonts: {
            "'Inter'": 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap'
          }
        },
        showPoweredBy: false
      },
      (error, result) => {
        if (!error && result) {
          if (result.event === 'success') {
            this.handleSuccessResult(result)
          }
        }
      }
    )
  }

  open(event) {
    event.preventDefault()
    this.uploader.open()
  }

  remove(event) {
    event.preventDefault()

    if (window.confirm('Are you sure?')) {
      // handle thumb preview
      this.thumbTarget.style = 'display: none !important';
      this.thumbTarget.dataset.imageSignedId = null
      this.thumbTarget.src = ''

      // hide toolbar if attached
      if (this.hasToolbarTarget) {
        this.toolbarTarget.style = 'display: none !important';
      }

      // mark for removal (on the backend)
      if (this.hasRemoveTarget) {
        this.removeTarget.value = '1'
      }

      if (this.hasPlaceholderTarget) {
        this.placeholderTarget.style.display = null
      }

      if (this.autoSubmitValue == true) {
        this.element.closest('form').requestSubmit()
      }
    }
  }

  // this is legacy code, it's not used anymore
  delete(event) {
    event.preventDefault()

    const imageDeleteButton = event.currentTarget
    const imageElement = imageDeleteButton.parentElement
    const imageSignedId = imageElement.dataset.imageSignedId
    const imageField = document.querySelector(`input[value="${imageSignedId}"]`)

    imageElement.remove()

    const allImageFields = document.querySelectorAll(`input[name="${this.fieldNameValue}[]"]`)

    if (allImageFields.length > 1) {
      imageField.remove()
    } else {
      imageField.value = ''
    }
  }

  async handleSuccessResult(result) {
    const response = await saveActiveStorageBlob(result)

    if (response.ok) {
      const body = await response.json
      this.handleUI(body)
    }
  }

  handleUI(response) {
    this.placeholderTarget.style = 'display: none !important';

    const cld = new Cloudinary({
      cloud: {
        cloudName: window.CLOUDINARY_CLOUD_NAME
      }
    })
    const image = cld.image(response.key)

    if (this.cropValue) {
      image.resize(fill().width(this.thumbWidthValue).height(this.thumbHeightValue).gravity(focusOn(FocusOn.face())))
    } else {
      image.resize(fill().width(this.thumbWidthValue).gravity(focusOn(FocusOn.face())))
    }

    const signedId = response.signed_id

    if (this.multipleValue) {
      const thumbContent = this.thumbTemplateTarget.innerHTML
        .replace(/THUMB_URL/g, image.toURL())
        .replace(/IMAGE_SIGNED_ID/g, signedId)
      this.thumbTarget.insertAdjacentHTML('beforeend', thumbContent)
    } else {
      this.thumbTarget.src = image.toURL()
      this.thumbTarget.style.display = null
      this.thumbTarget.dataset.imageSignedId = signedId
    }

    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', signedId)

    if (this.multipleValue) {
      hiddenField.setAttribute('name', `${this.fieldNameValue}[]`)
    } else {
      hiddenField.setAttribute('name', this.fieldNameValue)
    }

    this.element.appendChild(hiddenField)

    // show toolbar if attached
    if (this.hasToolbarTarget) {
      this.toolbarTarget.style.display = null;
    }

    if (this.hasRemoveTarget) {
      this.removeTarget.value = null;
    }

    if (this.autoSubmitValue == true) {
      this.element.closest('form').requestSubmit()
    }
  }
}
