import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {
  static targets = [
    'enableReturnsPortalCheckbox',
    'returnsPortalUrlInput',
    'returnsWebhookSecret',
  ]

  connect() {
    super.connect()
    this.enableReturnsPortal()
  }

  enableReturnsPortal() {
    const checkboxChecked = this.enableReturnsPortalCheckboxTarget.checked

    if (checkboxChecked) {
      this.show()
      this.returnsPortalUrlInputTarget.setAttribute('required', 'required')
      this.returnsWebhookSecretTarget.setAttribute('required', 'required')
    } else {
      this.hide()
      this.returnsPortalUrlInputTarget.removeAttribute('required')
      this.returnsWebhookSecretTarget.removeAttribute('required')
    }
  }
}
