import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'submit']

  toggle(event) {
    if(event.target.type == 'checkbox') {
      this.refresh()
    } else {
      event.target.querySelector('input[type="checkbox"]').toggleAttribute('checked')
      this.refresh()
    }
  }


  refresh() {
    let checked = this.checkboxTargets.filter((checkbox) => checkbox.checked)
    if (checked.length > 0) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
